<template>
  <div
      class="container inside-wrapper inside-wrapper-margin"
      id="case-study"
      data-aos="fade-up"
      data-aos-duration="1000"
  >

        <div class="row leacock-background-img back-white" style="">
            <div class="col-md-5 col-heading">
                <h2 class="heading wow fadeInDown text-blue">S. Archye Leacock <a href="https://sarchyeleacock.azurewebsites.net/"><span class="lnr lnr-link"></span></a></h2>
                <br>
                <p class="text-dark wow fadeInUp text-dark subheading text-justify">
                    S. Archye Leacock is looking to represent the Philadelphia's 9th District, covering parts of Northwest and Northeast communities, including East Mt. Airy, West Oak Lane, East Oak Lane, Olney, Lawncrest, Lawndale, Burholme and Oxford Circle.
                    <br><br>

                </p>
                <br><br>
                <a href="https://sarchyeleacock.azurewebsites.net/" class="web-blue" target="_blank"><span class="web-icon-blue"><i class="fa fa-globe "></i> </span> <span class="web-text-blue text-bold">View Website</span></a>
                <br><br><br>
            </div>
        </div>
        <section class="case-study">

            <div class="row">
                <div class="col-sm-12">
                    <div class="row margin-row-off">
                        <div class="col-sm-6">
                            <div class="margin-heading-top">

                                <h2 class="heding-title">1<span class="sub-title-text">Challenge</span></h2>
                            </div>

                            <p class="text-dark wow fadeInUp text-justify">
                                Any type of effective campaign hinges on its ability to produce publicity and attention for support of its candidate
                                or cause. Campaign websites have grown in popularity with the growth of the number of people connected to the Internet.
                                The percentage of major party candidates having their own campaign website has increased with 46% in 1998 to over 90%
                                today. However, even though web sites have the possibility to reach many, the message of the candidate must be presented
                                concisely with no room for misinterpretation.
                                <br><br>
                            </p>

                        </div>
                        <div class="col-sm-6">
                            <div class="app-screens">
                                <img width="100%" src="../../assets/img/casestudy-leacock/casestudy001.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="app-screens hidden-xs">
                                <img width="100%" src="../../assets/img/casestudy-leacock/casestudy002.jpg">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class=" margin-heading-top">
                                <h2 class="heding-title">2<span class="sub-title-pad sub-title-text">Obstacles</span></h2>
                            </div>
                            <ul type="none" class="contactPoints">
                                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Showcasing the extensive executive leadership experience of the candidate</span></li>
                                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Creating an ADA compliant website for the blind</span></li>
                                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Providing multiple ways to contribute to the campaign using Stripe, Paypal and any other fundraising platforms</span></li>
                                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Capturing data of visitors and volunteers to increase the candidates promoting power</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="margin-heading-top">
                                <h2 class="heding-title">3<span class="sub-title-pad ">Solution</span></h2>
                            </div>
                            <p class="text-dark wow fadeInUp text-justify">
                                Ocean Ring Technologies was tasked with building a website that was easy to update using a custom admin backend
                                where the data is stored in a Microsoft SQL Server running in Azure. The website is ADA compliant and the information
                                is accessible to those who are visually impaired including Mr. Leacock himself. Every web application we build is
                                responsive and checked for mobile viewing for both iOS and Android. This is important when the candidate is campaigning
                                for support and their site needs to allow supporters to make donations from their mobile device.
                                <br><br>
                            </p>
                        </div>
                        <div class="col-sm-6">
                            <div class="app-screens hidden-xs">
                                <img width="100%" src="../../assets/img/casestudy-leacock/casestudy003.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="app-screens">
                                <img width="100%" src="../../assets/img/casestudy-leacock/casestudy004.jpg">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="margin-heading-top">
                                <h2 class="heding-title">4<span class="sub-title-pad">Benefits</span></h2>
                            </div>
                            <p class="text-dark wow fadeInUp text-justify">
                                The benefits of having a campaign website is for the candidate to be able to provide their message
                                24/7 to any citizens of the community that wish to ensure on where the candidate stands for any
                                issues they are concerned. This also provides a method for supporters to donate to the candidate's
                                campaign as well as volunteer. Keeping citizens up to date on events and activities of the candidate
                                are of high importance as well as being able to provide a way for citizens to learn more about the
                                candidate.
                                <br><br>
                            </p>
                        </div>


                    </div>
                    <div class="col-sm-8  col-technologies text">
                        <h2 class="text-dark border-dotted-blue">Whichever services you require, we have the skills to match.</h2>
                        <router-link to="/contact" class="btn-red hvr-sweep-to-right hvr-sweep-to-right-red">Contact</router-link>
                    </div>
                </div>
            </div>
        </section>



<div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>

            </div>
</template>

<script>
export default {
  name: "work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
